(function(cssua){
	var safeVersion = 4.4;

	if(cssua.ua.android) {
		minchoPolyfill();

		if(parseFloat(cssua.ua.android) < safeVersion) {
			viewportUnitsPolyfill();
		}
	}

	// Android端末は明朝体が入っていないのでGoogle Fontsで代用
	function minchoPolyfill(){
		var font = document.createElement('link');
		font.type = 'text/css';
		font.rel = 'stylesheet';
		font.href = '//fonts.googleapis.com/earlyaccess/sawarabimincho.css';
		var l = document.getElementsByTagName('link')[0];

		return l.parentNode.insertBefore(font, l);
	}
	// Android v4.4以下のデフォルトブラウザでは vw, vh, vminが実装されていないのでJSで対応する
	function viewportUnitsPolyfill(){
		var script = document.createElement('script');
		script.type = 'text/javascript';
		script.src = '/mary/common/js/viewport-units-polyfill.min.js';
		var s = document.getElementsByTagName('script')[0];

		return s.parentNode.insertBefore(script, s.nextSibling);
	}
})(window.cssua);
